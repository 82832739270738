import { useTranslation } from "react-i18next";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import BigTitile from "../landing/BigTitle";

const EnrollmentBanner = () => {
	const { t } = useTranslation();

	return (
		<div className="container mx-auto">
			<div className="p-5 bg-white rounded-lg">
				<BigTitile
					title={t("label.join")}
					subtitle={t("label.exam_subtitle")}
				></BigTitile>
				<img alt="" src="/images/textbooks.png" className="mx-auto" />
				<div className="flex justify-center my-10">
					<Link to="/public/join" className="link-button">
						{t("button.more")}
						<RiArrowRightLine className="ml-2" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default EnrollmentBanner;
