import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { more, translateCell } from "../../providers/format";

const StudentList = ({ class_id, renderItem, creatable }) => {
	const { t } = useTranslation();

	return (
		<Table
			key={class_id}
			limit={50}
			defaultFind={{ role: "student", "student.class": class_id }}
			url={"/user/table"}
			paginationHide={!!renderItem}
			renderHeader={
				!creatable
					? null
					: () => {
							return (
								<div className="flex justify-end gap-2">
									<div className="mb-4">
										<Link
											to={`/app/class/view/${class_id}/student/add`}
											className="button"
										>
											{t("button.create_student")}
											<HiPlus className="text-lg ml-1" />
										</Link>
									</div>
								</div>
							);
					  }
			}
			renderItem={renderItem}
			columns={
				renderItem
					? []
					: [
							creatable && {
								key: "code",
								render: ({ _id }) =>
									more(`/app/class/view/${class_id}/student/view/${_id}`),
							},
							{
								key: "code",
								label: "field.code",
								sortable: true,
								filter: true,
							},
							{
								key: "register",
								label: "field.register",
								sortable: true,
								filter: true,
							},
							{
								key: "gender",
								label: "field.gender",
								sortable: true,
								filter: {
									type: "select",
									axio: "/const/static/gender",
									axioAdapter: (data) => {
										return data.map(({ _id }) => ({
											value: _id,
											label: t(`const.gender.${_id}`),
										}));
									},
								},
								render: (row) => translateCell(row, t, "gender", "gender"),
							},
							{
								key: "lastname",
								label: "field.lastname",
								sortable: true,
								filter: true,
							},
							{
								key: "firstname",
								label: "field.firstname",
								sortable: true,
								filter: true,
							},
							{
								key: "phone",
								label: "field.phone",
								sortable: true,
								filter: true,
							},
							{
								key: "address",
								label: "field.address",
								sortable: true,
								filter: true,
							},
							{
								key: "student.parent",
								label: "field.parent",
								render: ({ student }) => {
									if (student && student.parents)
										return (
											<div className="whitespace-nowrap">
												{student.parents.map(({ parent, relation }) => (
													<div key={parent?._id}>
														{parent?.code} {t(`const.relation.${relation}`)}{" "}
														{parent?.fullname} ({parent?.phone})
													</div>
												))}
											</div>
										);
								},
							},
					  ]
			}
		></Table>
	);
};

export default StudentList;
