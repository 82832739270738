import { Outlet, useLocation } from "react-router-dom";
import { RiMenuFill, RiLoginBoxLine } from "react-icons/ri";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../language/Switch";
import { useApp } from "../providers/app";
import { APP_STORE, PLAY_STORE, YEAR } from "../defines";
import ContactDetails from "../components/landing/ContactDetails";
import Socials from "../components/landing/Socials";
import { useEffect } from "react";

const MENU = [
	{ label: "home", to: "/public/home" },
	{ label: "employee", to: "/public/employee" },
	{ label: "article", to: "/public/article" },
	{ label: "join", to: "/public/join" },
	{ label: "book", to: "/public/book" },
	{ label: "contact", to: "/public/contact" },
	{
		label: "transparent",
		to: "/public/report",
		// to: "https://shilendans.gov.mn/organization/18928",
		// target: "_blank",
	},
	{
		label: "eljur",
		to: "https://oros-3.eljur.ru/authorize",
		target: "_blank",
	},
];

const PublicMenu = () => {
	const { openDrawer } = useApp();
	const { t } = useTranslation();

	const openMobileMenu = () => {
		openDrawer(
			MENU.map(({ label, to, target }, index) => (
				<NavLink
					key={index}
					to={to}
					target={target}
					className={({ isActive }) =>
						`${
							isActive ? "text-secondary-100" : "text-dark-40"
						} pointer block w-full border-b p-2`
					}
				>
					{t(`menu.${label}`)}
				</NavLink>
			))
		);
	};

	return (
		<div className="sticky top-0 bg-white h-20 z-20 flex items-center shadow-md">
			<div className="container mx-auto px-4 flex items-center justify-between">
				<div className="lg:flex hidden items-center">
					<img src="/logo.jpg" alt="logo" className="h-10" />
					<div
						className="uppercase text-secondary-100 ml-1 leading-4"
						style={{ width: 225 }}
					>
						{t("label.school_name")}
					</div>
				</div>
				<div
					className="lg:flex hidden gap-5 text-dark-40 font-medium text-sm"
					style={{ fontSize: 13 }}
				>
					{MENU.map(({ label, to, target }, index) => (
						<NavLink
							key={index}
							to={to}
							target={target}
							className={({ isActive }) =>
								`${isActive ? "text-secondary-100" : ""} pointer`
							}
						>
							{t(`menu.${label}`)}
						</NavLink>
					))}
				</div>
				<div
					className="lg:hidden icon-button border border-dark-60 text-dark-60"
					onClick={openMobileMenu}
				>
					<RiMenuFill />
				</div>
				<div className="flex item-center">
					<LanguageSwitch />
					<Link
						className="ml-4 icon-button bg-secondary-100 text-white"
						to="/auth/login"
					>
						<RiLoginBoxLine />
					</Link>
				</div>
			</div>
		</div>
	);
};

const FOOTER_PAGES = [
	{
		label: "menu.school",
		to: "/public/school",
	},
	{
		label: "menu.campus",
		to: "/public/campus",
	},
	{
		label: "menu.transparent",
		to: "https://shilendans.gov.mn/organization/18928",
		target: "_blank",
	},
	{
		label: "menu.employee",
		to: "/public/employee",
	},
	{
		label: "menu.article",
		to: "/public/article",
	},
	{
		label: "menu.login",
		to: "/auth/login",
	},
];

const PublicFooter = () => {
	const { t } = useTranslation();

	return (
		<div className="border-t">
			<div className="bg-white p-2 py-5">
				<div className="container mx-auto md:flex flex-wrap gap-5 grid grid-cols-1 justify-between text-sm leading-7 text-dark-80 text-center md:text-left">
					<div className="self-center lg:flex hidden">
						<img src="/logo.jpg" alt="logo" style={{ height: 100 }} />
					</div>
					<div>
						<ContactDetails />
						<Socials />
					</div>
					<div>
						<div className="text-secondary-100 uppercase">
							{t("label.pages")}
						</div>
						<div className="flex flex-col">
							{FOOTER_PAGES.map(({ to, label, target }) => (
								<Link key={label} to={to} className="link" target={target}>
									{t(label)}
								</Link>
							))}
						</div>
					</div>
					<div>
						<div className="text-secondary-100 uppercase">
							{t("label.download")}
						</div>
						<div className="flex flex-col items-center md:items-start">
							<a
								href={PLAY_STORE}
								target="_blank"
								rel="noreferrer"
								className="mb-2"
							>
								<img src="/images/playstore.png" alt="OROS 3" />
							</a>
							<a href={APP_STORE} target="_blank" rel="noreferrer">
								<img src="/images/appstore.png" alt="OROS 3" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-secondary-100 text-center p-2 text-white text-sm">
				<div>
					© {YEAR()} {t("label.ministry")}
				</div>
				<div>{t("label.school_name")}</div>
			</div>
		</div>
	);
};

const PublicLayout = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location]);

	return (
		<div className="w-full">
			<PublicMenu></PublicMenu>
			<Outlet />
			<PublicFooter></PublicFooter>
		</div>
	);
};

export default PublicLayout;
