import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiSearch2Line } from "react-icons/ri";
import { translateError } from "../../providers/alert";
import { Loader } from "../../template";
import { mainApi } from "../../providers/api";
import { getImageSrc } from "../../providers/utils";
import objectPath from "object-path";

const Search = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState("");
	const [phone, setPhone] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [register, setRegister] = useState("");
	const [items, setItems] = useState([]);

	const search = async () => {
		try {
			setLoading(true);
			if (!code && !phone && !firstname && !lastname && !register)
				throw new Error("Хайлтын утга оруулна уу");

			const response = await mainApi({
				url: "/user/search",
				method: "POST",
				data: {
					code,
					phone,
					firstname,
					lastname,
					register,
				},
			});

			setItems(response.data);
		} catch (error) {
			translateError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="relative">
			{loading && <Loader></Loader>}
			<div className="grid grid-cols-6 gap-5">
				<input
					value={code}
					onChange={(e) => setCode(e.target.value)}
					placeholder={t("field.code")}
					onKeyDown={(e) => {
						if (e.key === "Enter") search();
					}}
				></input>
				<input
					value={register}
					onChange={(e) => setRegister(e.target.value)}
					placeholder={t("field.register")}
					onKeyDown={(e) => {
						if (e.key === "Enter") search();
					}}
				></input>
				<input
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					placeholder={t("field.phone")}
					onKeyDown={(e) => {
						if (e.key === "Enter") search();
					}}
				></input>
				<input
					value={firstname}
					onChange={(e) => setFirstname(e.target.value)}
					placeholder={t("field.firstname")}
					onKeyDown={(e) => {
						if (e.key === "Enter") search();
					}}
				></input>
				<input
					value={lastname}
					onChange={(e) => setLastname(e.target.value)}
					placeholder={t("field.lastname")}
					onKeyDown={(e) => {
						if (e.key === "Enter") search();
					}}
				></input>
				<button
					onClick={() => {
						search();
					}}
				>
					<RiSearch2Line className="text-lg mr-2" />
					{t("button.search")}
				</button>
			</div>
			<div className="mt-5 flex flex-wrap gap-2">
				{items.map((item) => (
					<div key={item._id} className="p-2 bg-white text-sm w-48">
						<img
							alt=""
							className="h-48 w-full object-cover"
							crossOrigin="anonymous"
							src={`${getImageSrc(item.profile)}`}
						/>
						<div className="p-2">
							<div>{item.code}</div>
							<div>{item.register}</div>
							<div>
								{item.role === "student" &&
									`${objectPath.get(item, "student.class.current")} `}
								{t(`const.role.${item.role}`)}
							</div>
							<div>{item.fullname}</div>
							<div>{item.phone}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Search;
