import { RiFacebookFill, RiYoutubeFill, RiMailFill } from "react-icons/ri";

const SOCIALS = [
	{
		icon: <RiFacebookFill></RiFacebookFill>,
		href: "https://www.facebook.com/mon.rus.school3",
	},
	{ icon: <RiMailFill></RiMailFill>, href: "mailto:info@oros3.mn" },
	{
		icon: <RiYoutubeFill></RiYoutubeFill>,
		href: "https://www.youtube.com/@dailymediamrjs",
	},
];

const Socials = () => {
	return (
		<div className="flex gap-2 mt-5 justify-center md:justify-start">
			{SOCIALS.map(({ icon, href }, index) => (
				<a
					key={index}
					href={href}
					target="_blank"
					rel="noreferrer"
					className="icon-button bg-secondary-10 text-secondary-100"
				>
					{icon}
				</a>
			))}
		</div>
	);
};

export default Socials;
