import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loader } from "../template";
import { getAbsolutePath, getTranslated } from "../providers/format";
import { renderMedia } from "../inputs/FileUpload";
import { Article as RecentArticle } from "../components/article/Recent";
import MSwiper from "../components/Swiper";

const Article = () => {
	const { id } = useParams();
	const { t, i18n } = useTranslation();
	const [article, setArticle] = useState();
	const [media, setMedia] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios(`public/article/${id}`);
			setArticle(response.data);
			const images = (response.data?.media || []).map(getAbsolutePath);
			setMedia(images);
		};

		if (id) fetchData();
	}, [id]);

	const content = getTranslated(i18n.language, article, "content");

	return (
		<div className="container mx-auto bg-white p-2 relative">
			<div className="md:w-2/3 w-full mx-auto">
				{!article && <Loader />}
				<RecentArticle {...article} height={500}></RecentArticle>
				<div className="mb-10 border-b"></div>
				<div className="text-dark-100 whitespace-pre-line flex-1 mb-5 text-sm">
					{content}
				</div>
				{media && <MSwiper height={300} items={media}></MSwiper>}
				{article?.attachments?.length > 0 && (
					<div className="mb-5 text-dark-100">
						{t("field.attachments")}
						<div className="gap-2 flex flex-wrap my-2">
							{article.attachments.map((pdf, index) => {
								return (
									<div key={index} className="p-2 border">
										{renderMedia(pdf, false)}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Article;
