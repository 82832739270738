import { useTranslation } from "react-i18next";
import AppMockup from "../components/landing/AppMockup";
import Intro from "../components/landing/Intro";
import RecentArticles from "../components/article/Recent";
import FeaturedEmployees from "../components/employee/Featured";
import Directory from "../components/landing/Directory";
import EnrollmentBanner from "../components/enrollment/Banner";
import BigTitile from "../components/landing/BigTitle";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div>
			<RecentArticles></RecentArticles>
			<EnrollmentBanner></EnrollmentBanner>
			<FeaturedEmployees></FeaturedEmployees>
			<Intro></Intro>
			<div className="bg-white p-10">
				<div className="container mx-auto ">
					<BigTitile
						title={t("label.togtool")}
						subtitle={t("label.togtool_sub")}
					></BigTitile>
					<div
						style={{ height: 700, position: "relative" }}
						className="overflow-hidden"
					>
						<iframe
							title={t("label.togtool")}
							className="w-full min-h-full"
							src="/pdf/togtool.pdf"
							loading="lazy"
						/>
					</div>
				</div>
			</div>
			<Directory></Directory>
			<AppMockup></AppMockup>
		</div>
	);
};

export default Home;
