import { useTranslation } from "react-i18next";
import BigTitile from "./BigTitle";

const Intro = () => {
	const { t } = useTranslation();
	return (
		<div className="p-10 bg-white relative flex justify-center border-t">
			<img
				className="absolute top-10 right-0"
				alt="pattern"
				src="/images/wave.png"
			/>
			<img
				className="absolute bottom-0 left-0"
				alt="pattern"
				src="/images/circles.png"
			/>
			<div className="flex-1 z-10 items-center mb-10">
				<BigTitile
					title={t("label.school_title")}
					subtitle={t("label.school_subtitle")}
				></BigTitile>
				<div className="w-full lg:w-1/2 mx-auto">
					<video src="/video/rus3.mp4" controls loading="lazy"></video>
				</div>
			</div>
		</div>
	);
};
export default Intro;
