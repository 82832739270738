import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { fileValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const ReportForm = ({ type }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "report",
		id,
		init: {
			type,
			published: true,
			title: "",
			thumb: [],
			article: [],
			grades: [],
		},
		validationSchema: Yup.object().shape({
			title: stringValidation(true),
			thumb: fileValidation(),
			article: fileValidation(),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.article_info")}</h2>
							<Field
								name="published"
								label="field.published"
								disabled={disabled}
								component={Toggle}
							/>
							<Field
								name="date"
								label="field.date"
								disabled={disabled}
								component={DatePicker}
								time={true}
							/>
							<Field
								name="title"
								label="field.title"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="trans.ru.title"
								label="field.title_ru"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="trans.us.title"
								label="field.title_us"
								disabled={disabled}
								component={Text}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="thumb"
								label="field.thumb"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="report"
							/>
							<Field
								name="content"
								label="field.content"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="report"
								limit={10}
								accept={{ "application/pdf": [".pdf"] }}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default ReportForm;
