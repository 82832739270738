/* eslint-disable */

import { useTranslation } from "react-i18next";
import { useApp } from "../../providers/app";
import Vote from "./Vote";
import { RiDownload2Fill, RiFileExcelLine } from "react-icons/ri";
import { useRef, useState } from "react";
import html2canvas from "html2canvas";
import { mainApi } from "../../providers/api";
import { DEBUG } from "../../defines";
import { Loader } from "../../template";

import * as XLSX from "xlsx";
import { format, parseISO } from "date-fns";

const PollView = ({ _id, title, roles, options = [], type, ...props }) => {
	const { t } = useTranslation();
	const { user } = useApp();

	const supervisor = ["admin", "teacher"].includes(user.role);

	const exportRef = useRef();

	const exportToPng = async () => {
		const element = exportRef.current;
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL("image/png");

		var a = document.createElement("a");
		a.href = data;
		a.download = `${format(new Date(), "yyyy-MM-dd HH:mm:ss")}.png`;
		a.click();
	};

	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi({
				method: "GET",
				url: `/poll/${_id}/vote/export`,
			});

			await exportToExcel("Poll " + format(new Date(), "yyyy-MM-dd HH:mm:ss"), {
				sheets: response.data.sheets,
				votes: response.data.votes.reduce((prev, cur) => {
					prev[cur.user] = {
						response: options[+cur.response]?.text,
						responded: format(parseISO(cur.created), "yyyy-MM-dd HH:mm:ss"),
						comment: cur.comment,
					};
					return prev;
				}, {}),
			});
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const exportToExcel = (filename, { sheets, votes }) => {
		const header = [
			"Анги",
			"Хэрэглэгчийн код",
			"Хандах эрх",
			"Нэр",
			"Хариулт",
			"Хариулсан огноо",
			"Үндэслэл, тайлбар",
		];

		const data = [];
		sheets.map((sheet) => {
			if (roles.includes("student"))
				sheet.students.map((s) => {
					const class_name = sheet.class;
					const code = s.code;
					const role = "Сурагч";
					const name = s.firstname;
					const response = votes[s.id]?.response;
					const responded = votes[s.id]?.responded;
					const comment = votes[s.id]?.comment;

					data.push([
						class_name,
						code,
						role,
						name,
						response,
						responded,
						comment,
					]);
				});
			if (roles.includes("parent"))
				sheet.parents.map((p) => {
					const class_name = sheet.class;
					const code = p.code;
					const role = "Асран хамгаалагч";
					const name = `${p.firstname} /${p.student}-н ${t(
						`const.relation.${p.relation}`,
						{ lng: "MN" }
					)}/`;
					const response = votes[p.id]?.response;
					const responded = votes[p.id]?.responded;
					const comment = votes[p.id]?.comment;

					data.push([
						class_name,
						code,
						role,
						name,
						response,
						responded,
						comment,
					]);
				});
		});

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([header, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = [
			{
				wch: 10,
			},
			{
				wch: 30,
			},
			{
				wch: 30,
			},
			{
				wch: 30,
			},
			{
				wch: 40,
			},
			{
				wch: 40,
			},
			{
				wch: 50,
			},
		];

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	if (options.length > 0)
		return (
			<div className="p-5 bg-white flex flex-wrap gap-5">
				{loading && <Loader />}
				{supervisor && (
					<div className="flex-1" ref={exportRef}>
						<h2 className="bordered">{t("title.poll_info")}</h2>
						<div className="flex justify-end">
							<div>
								<button
									type="button"
									onClick={() => {
										exportToPng();
									}}
									className="button gray"
								>
									PNG <RiDownload2Fill className="text-lg ml-1" />
								</button>
							</div>
							<div className="ml-2">
								<button
									type="button"
									onClick={() => {
										onClickDownload();
									}}
									className="button gray"
								>
									XLSX <RiFileExcelLine className="text-lg ml-1" />
								</button>
							</div>
						</div>
						<div>
							<h1 className="my-4 text-dark-100">
								{type === "class"
									? Array.isArray(props.class)
										? props.class?.map((c) => c.current).join(", ")
										: props.class?.current
									: t(`const.sigment_type.${type}`)}
								: {title}
							</h1>
							{/* <Pie
								data={options.map(({ text, voted }) => ({
									value: voted,
									name: text,
								}))}
								height={500}
								renderLabel={({ name, value }) => `${name}: ${value}`}
							/> */}
						</div>
					</div>
				)}
				<div className="flex-1 border-l pl-4">
					<Vote options={options} {...props}></Vote>
				</div>
			</div>
		);

	return null;
};

export default PollView;
