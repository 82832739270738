import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	RiTeamLine,
	RiUserStarLine,
	RiUserHeartLine,
	RiBook2Line,
	RiVideoLine,
	RiNewspaperLine,
} from "react-icons/ri";
import { useEffect, useState } from "react";
import { mainApi } from "../providers/api";
import { HiChevronRight } from "react-icons/hi";
import TaskDashboard from "../components/task/Dashboard";

const AdminDashboard = () => {
	const { t } = useTranslation();
	const [items, setItems] = useState([]);
	const [feedbacks, setFeedbacks] = useState([]);
	const [polls, setPolls] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const dashboard = await mainApi("/dashboard/admin");
			const {
				teachers,
				students,
				parents,
				announcements,
				ebooks,
				lessons,
				feedbacks,
				polls,
			} = dashboard.data;

			setItems([
				{
					icon: <RiTeamLine />,
					label: "title.employee",
					to: "/app/employee",
					count: teachers,
				},
				{
					icon: <RiUserStarLine />,
					label: "title.student",
					to: "/app/student",
					count: students,
				},
				{
					icon: <RiUserHeartLine />,
					label: "title.parent",
					to: "/app/parent",
					count: parents,
				},
				{
					icon: <RiNewspaperLine />,
					label: "title.announcement",
					to: "/app/article",
					count: announcements,
				},
				{
					icon: <RiBook2Line />,
					label: "title.book",
					to: "/app/book",
					count: ebooks,
				},
				{
					icon: <RiVideoLine />,
					label: "title.lesson",
					to: "/app/lesson",
					count: lessons,
				},
			]);
			setFeedbacks(feedbacks);
			setPolls(polls);
		};

		fetchData();
	}, []);

	return (
		<div className="w-full flex gap-5">
			<div className="flex-1">
				<h2 className="bordered">{t("title.statitic")}</h2>
				<div className="grid grid-cols-3 gap-5 mt-5 text-dark-100">
					{items.map(({ label, icon, count, to }) => (
						<div key={to} className="bg-white shadow-md p-5 relative">
							<div className="flex items-center">
								<div className="mr-2">{icon}</div>
								{t(label)}
							</div>
							<h2 className="text-4xl mt-2 mb-10">{count}</h2>
							<div className="absolute bottom-0 right-0 text-8xl opacity-10">
								{icon}
							</div>
						</div>
					))}
				</div>
				<div className="mt-5">
					<TaskDashboard></TaskDashboard>
				</div>
				{/* <h2 className="bordered mt-10">{t("title.dashboard_student")}</h2>
				<div className="bg-white mt-5">
					<NoInfo />
				</div>
				<h2 className="bordered mt-10">{t("title.dashboard_employee")}</h2>
				<div className="bg-white mt-5">
					<NoInfo />
				</div> */}
			</div>
			<div>
				<div style={{ width: 300 }} className="bg-white p-5 relative">
					<h2 className="bordered">{t("title.complain")}</h2>
					{feedbacks.map(({ _id, content, status, _created }) => (
						<div key={_id} className="flex mb-2 pb-2 border-b">
							<div>
								<div
									className={`my-2 ${
										status === "pending" ? "text-primary-100" : "text-green-400"
									}`}
								>
									{t(`const.feedback_status.${status}`)}
								</div>
								<div>{content}</div>
								<div className="text-sm text-secondary-100 mt-2">
									{_created}
								</div>
							</div>
							<Link to={`/app/feedback/view/${_id}`} className="self-center">
								<HiChevronRight className="text-secondary-100 text-2xl" />
							</Link>
						</div>
					))}
					<div className="text-secondary-100 text-right">
						<Link to="/app/feedback">{t("button.view_all")}</Link>
					</div>
					<h2 className="bordered mt-10">{t("title.survey")}</h2>
					{polls.map(({ _id, title, createdby, _created }) => (
						<div key={_id} className="flex mb-2 pb-2 border-b">
							<div>
								<div className="my-2">{title}</div>
								<div className="text-sm font-medium">{createdby?.fullname}</div>
								<div className="text-sm text-secondary-100">{_created}</div>
							</div>
							<Link to={`/app/poll/view/${_id}`} className="self-center">
								<HiChevronRight className="text-secondary-100 text-2xl" />
							</Link>
						</div>
					))}
					<div className="text-secondary-100 text-right">
						<Link to="/app/poll">{t("button.view_all")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
