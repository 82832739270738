import { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";
import { locales } from "../language/Switch";

window.addEventListener("storage", (event) => {
	if (event.key === "logged-out") {
		window.location = "/auth/logout";
		window.close();
	}
});

const AppContext = createContext({
	user: null,
	badge: 0,
	login: () => {},
	logout: () => {},
	openDrawer: () => {},
	closeDrawer: () => {},
});

const AppProvider = ({ children }) => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [user, setUser] = useState();
	const [drawer, setDrawer] = useState();
	const [drawerWidth, setDrawerWidth] = useState(0);

	const login = async (instance) => {
		setInstance(instance);
		navigate("/app/dashboard");
	};

	const logout = () => {
		setInstance();
	};

	const setInstance = (instance) => {
		if (instance) {
			SaveToStorage("refreshToken", instance.refreshToken);
			SaveToStorage("accessToken", instance.accessToken);
			setUser(instance.user);
		} else {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");
			setUser(null);
		}
	};

	const openDrawer = (content, width = 250) => {
		setDrawer(content);
		setDrawerWidth(width);
	};

	const closeDrawer = () => {
		setDrawer(null);
		setDrawerWidth(0);
	};

	useEffect(() => {
		const refreshUserToken = async (token) => {
			if (token) {
				try {
					const response = await mainApi({
						method: "POST",
						url: "/user/refresh",
						data: {
							token,
						},
					});
					response && setInstance(response.data);
				} catch {}
			}
		};

		refreshUserToken(GetFromStorage("refreshToken"));
	}, []);

	useEffect(() => {
		document.title = t("label.school_name");
		if (!locales.includes(i18n.language)) {
			i18n.changeLanguage(locales[0]);
		}
		// eslint-disable-next-line
	}, [i18n.language]);

	return (
		<AppContext.Provider
			value={{ user, login, logout, openDrawer, closeDrawer }}
		>
			{drawer && (
				<div
					className="fixed top-0 left-0 bottom-0 right-0 z-30 flex overflow-y-auto"
					onClick={closeDrawer}
				>
					<div
						style={{ width: drawerWidth }}
						className="flex-grow-0 bg-white shadow-md z-50 transition-all relative"
					>
						<div className="absolute top-0 right-0 icon-button text-dark-60">
							<RiCloseFill />
						</div>
						<img src="/logo.jpg" className="h-20 my-5 mx-auto" alt="logo" />
						<div className="text-secondary-100 text-center pb-5 border-b">
							<Trans t={t}>label.school_name</Trans>
						</div>
						{drawer}
					</div>
					<div className="flex-1 bg-black bg-opacity-80 z-40"></div>
				</div>
			)}
			{children}
		</AppContext.Provider>
	);
};

export const useApp = () => useContext(AppContext);
export default AppProvider;
